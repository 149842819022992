<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        title="电站列表"
        :data="tableData"
        :columns="tableColumns"
        :selectable="true"
        :rowSelectable="row => true"
        :selectOnIndeterminate="false"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())"
        @selection-change="selectionChange">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button
              v-rbac="'operation:price:place:config'"
              type="plain"
              @click="handleBatchSet">
              批量设置
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.pageIndex"
          :page-size.sync="pageInfo.pageSize"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
    <ykc-drawer
      :show.sync="showDrawer"
      title="占位价格策略"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button
              style="margin-left: 8px"
              :type="btn.type"
              v-if="btn.enabled()"
              :key="i"
              @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <AddOrEdit
          ref="addOrEdit"
          :treeData="treeData"
          :selectedRows="selectedRows"
          :currentRow="currentRow"
          :operateType="operateType"></AddOrEdit>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  import { code } from '@/utils';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { occupyStrategy, treeUtils, directPage } from '@/service/apis';

  import AddOrEdit from './AddOrEdit.vue';

  export default {
    name: 'OccupyPriceStrategyList',
    components: {
      AddOrEdit,
    },
    data() {
      return {
        currentRow: null,
        showDrawer: false,
        operateType: '',
        treeData: [],
        selectedRows: [],

        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => true,
            handler: () => {
              this.$refs.addOrEdit.submitForm(() => {
                this.showDrawer = false;
                this.searchTableList();
              });
            },
          },
        ],
        tableData: [],
        tableOperateButtons: [
          {
            enabled: () => code('operation:price:place:edit'),
            render: (h, button, { row }) => {
              return (
                <ykc-button
                  type="text"
                  class="do-enable-btn"
                  onClick={() => {
                    this.doEdit(row);
                  }}>
                  编辑
                </ykc-button>
              );
            },
          },
          {
            enabled: () => code('operation:price:place:detail'),
            render: (h, button, { row }) => {
              if (String(row.status) === '0') {
                return null;
              }
              return (
                <ykc-button
                  type="text"
                  class="do-enable-btn"
                  onClick={() => {
                    this.doDetail(row);
                  }}>
                  详情
                </ykc-button>
              );
            },
          },
        ],
        tableColumns: [
          { label: '电站编码', prop: 'stationCode', minWidth: '200px' },
          {
            label: '电站名称',
            prop: 'stationName',
            minWidth: '200px',
            // scopedSlots: {
            //   default: ({ row }) => {
            //     return (
            //       <el-tooltip effect="dark" content={row.stationName} placement="top-start">
            //         <span>{row.stationName ?? '--'}</span>
            //       </el-tooltip>
            //     );
            //   },
            // },
          },
          {
            label: '归属商家',
            prop: 'stationOperatorName',
            minWidth: '200px',
            // scopedSlots: {
            //   default: ({ row }) => {
            //     return (
            //       <el-tooltip effect="dark" content={row.stationOperatorName} placement="top-start">
            //         <span>{row.stationOperatorName}</span>
            //       </el-tooltip>
            //     );
            //   },
            // },
          },
          {
            label: '是否收取',
            prop: 'status',
            scopedSlots: {
              defaultTitle: () => '',
              default: ({ row: { status } }) => {
                const statusText = {
                  0: '否',
                  1: '是',
                }[status];
                return statusText || '一';
              },
            },
          },
          { label: '修改人', prop: 'modifiedAccount' },
          { label: '修改时间', prop: 'modifiedTime', minWidth: '200px' },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          stationName: '',
          stationOperatorId: '',
          status: '',
        },
        OperatorListData: [], // 归属商户
      };
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'stationName',
            label: '电站名称',
            placeholder: '请输入电站名称',
          },
          {
            comName: 'YkcDropdown',
            key: 'stationOperatorId',
            label: '归属商家',
            placeholder: '请输入归属商家',
            data: this.OperatorListData,
            remote: true,
          },
          {
            comName: 'YkcDropdown',
            key: 'status',
            label: '状态',
            placeholder: '请选择状态',
            data: [
              { id: '', name: '全部' },
              { id: '0', name: '否' },
              { id: '1', name: '是' },
            ],
          },
        ];
      },
    },
    created() {
      this.searchTableList();
      this.requestOperatorList();
    },
    methods: {
      requestOperatorList() {
        directPage.userOperatorList({ keyword: '' }).then(res => {
          // eslint-disable-next-line no-unused-vars
          this.OperatorListData = (res || []).map(({ operatorId, name }) => ({
            name,
            id: String(operatorId),
          }));
        });
      },
      async doEdit(row) {
        await this.getTreeData();
        const rowDetail = await this.getDetail(row);
        this.operateType = 'single';
        this.currentRow = {
          ...row,
          ...(rowDetail || {}),
        };
        this.showDrawer = true;
      },
      async handleBatchSet() {
        if (this.selectedRows.length > 0) {
          await this.getTreeData();
          this.operateType = 'multiple';
          this.showDrawer = true;
        } else {
          this.$message.warning('请选择数据');
        }
      },
      async getDetail({ stationId }) {
        const response = await occupyStrategy.detail({ stationId });
        console.log('getDetail response', response);
        return response;
      },
      async getTreeData() {
        const res = await treeUtils.operatorUsers({});
        console.log('getTreeData', res);
        const random = Math.random();
        this.treeData = Object.entries(res).map(([label, children], index) => {
          const id = `${random}${index}`;
          children.forEach(item => {
            item.id = String(item.id);
            item.label = item.name;
          });
          return {
            id,
            label,
            children,
          };
        });
      },
      doDetail({ stationId }) {
        this.$router.push({
          path: '/operationCenter/priceManage/occupyingPriceStrategy/details',
          query: {
            stationId,
          },
        });
      },
      searchTableList() {
        const data = {
          ...this.searchParams,
          size: this.pageInfo.pageSize,
          current: this.pageInfo.pageIndex,
        };
        occupyStrategy.queryForPage(data).then(res => {
          this.tableData = res.records;
          this.pageInfo.total = res.total;
          this.pageInfo.pageIndex = res.current;
        });
      },

      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },

      /** 点击查询按钮 */
      handleSearchConfirm() {
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },
      onAddDrawerClose(done) {
        done();
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
          },
        });
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      /** 获取勾选id */
      selectionChange(selection) {
        this.selectedRows = [...selection];
      },
    },
  };
</script>

<style lang="scss" scoped></style>
